<script setup lang="ts">
  import { init } from '@/helpers/responsive'
  import { useUserStore } from '@/store/User'
  import { defineAsyncComponent } from 'vue'
  import { addGnbGaEvents } from './gnb/addGaEvents'
  import { capitalize } from './helpers/converter'
  import { useOverlayCtaStore } from './store/OverlayCta'
  const OverlayCta = defineAsyncComponent(() => import('./components/Common/OverlayCta.vue'))

  const userStore = useUserStore()
  const route = useRoute()

  const overlayStore = useOverlayCtaStore()

  const seoTitle = computed(() => capitalize(route.meta.seo?.title ?? route.meta.menuTitle ?? ''))

  useHead({
    title: () => seoTitle.value,
    titleTemplate: 'MapleStory | %s',
    meta: [
      {
        name: 'description',
        content: () => route.meta.seo && route.meta.seo.description,
      },
      {
        name: 'keywords',
        content: () => route.meta.seo && route.meta.seo.keywords,
      },
    ],
  })

  watch(
    () => route.meta?.gaPageType,
    (val) => {
      if (val) {
        window.dataLayer.push({
          pageType: val,
        })
      }
    }
  )

  onMounted(async () => {
    window.version = `Maplestory Website Version : ${VITE_APP_VERSION}`
    console.log(window.version)
    userStore.getUserInfo()
    init()
    addGnbGaEvents()
  })
</script>
<template>
  <NavigationBar />
  <div class="body">
    <RouterView />
  </div>

  <OverlayCta v-if="overlayStore.state.isActive" />
  <LightBox />
  <ScrollToTopButton />
</template>

<style lang="scss">
  @media (max-width: 375px) {
    // temporary fixed the GNB for screens smaller than 375px
    #globalNavigation,
    .global-navigation,
    #globalFooter {
      min-width: 100% !important;
    }
  }
</style>
@/config/overlayCta/overlay
