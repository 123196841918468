import { defineStore } from 'pinia'

export const useGAEventStore = defineStore('GAEventTracking', () => {
  const getSectionDataSets = (el: HTMLElement) => {
    while (el.dataset.sectionNumber === undefined && el.parentElement != null) {
      el = el.parentElement
    }
    return {
      event: 'standardTrackingEvent',
      sectionNumber: Number(el.dataset.sectionNumber),
      sectionName: el.dataset.sectionName,
      ...(el.dataset.eventDetails ? { eventDetails: el.dataset.eventDetails } : {}),
      ...(el.dataset.interaction ? { interaction: el.dataset.interaction } : {}),
      ...(el.dataset.sectionContent ? { sectionContent: el.dataset.sectionContent } : {}),
    }
  }

  const pushSectionViewEvent = (el: HTMLElement, additionalData: any) => {
    const sections = getSectionDataSets(el)
    // order : additionalData > dataset > default
    const data = {
      interaction: 'section_view',
      ...sections,
      ...(typeof additionalData === 'object' ? additionalData : {}),
    }

    window.dataLayer.push(data)
    console.log('pushSectionViewEvent', data)
  }
  const pushActionEvent = (additionalData: object, el?: HTMLElement) => {
    const data = {
      event: 'standardTrackingEvent',
      ...(el ? getSectionDataSets(el) : {}),
      ...additionalData,
    }
    window.dataLayer.push(data)
    console.log('pushActionEvent', data)
  }

  return {
    pushSectionViewEvent,
    pushActionEvent,
  }
})
