import MainMenu from '@/views/MainMenu.vue'
import type { RouteRecordRaw } from 'vue-router'

const eventsRoute: RouteRecordRaw = {
  path: '/events',
  name: 'events',
  component: MainMenu,
  redirect: 'marvel-machine',
  meta: {
    menuTitle: 'Events',
  },
  children: [
    {
      path: '/marvel-machine',
      name: 'marvel-machine',
      component: () => import('@/views/events/MarvelMachine.vue'),
      meta: {
        menuTitle: 'Marvel Machine',
        fullPage: true,
        seo: {
          description: 'Play to win 3 in-game items! Then double one of those items for 1,000 NX!',
          keywords:
            'maplestory marvel machine, marvel machine, maplestory double marvel, marvel machine item list, gms marvel machine, marvel machine prize list, maplestory marvel codes',
        },
        gaPageType: 'marvel machine',
      },
    },
    {
      path: '__', // external
      name: 'maplestory-fest',
      redirect: '/maplestoryfest', // external
      meta: {
        menuTitle: 'MapleStory Fest',
      },
    },
    {
      path: 'maple-lane',
      name: 'maple-lane',
      component: () => import('@/views/events/MapleLane/index.vue'),
      meta: {
        menuTitle: 'A Trip Down Maple Lane',
        subTitle: "look at what you've achieved in maplestory by year!",
        banners: ['player-ranking', 'news', 'classes-and-jobs'],
        seo: {
          keywords:
            'maplestory maple lane, gms maple lane, gms year in review, year in review maplestory, 2024 year in review maplestory, maple lane cards, maple lane card rarity',
        },
      },
      children: [
        {
          path: ':year',
          name: 'maple-lane-year',
          component: () => import('@/views/events/MapleLane/Year.vue'),
        },
      ],
    },
  ],
}

export default eventsRoute
