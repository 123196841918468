import type { RouteRecordRaw } from 'vue-router'
import MainMenu from '@/views/MainMenu.vue'

const communityRoute: RouteRecordRaw = {
  path: '/community',
  name: 'community',
  component: MainMenu,
  redirect: { name: 'main' },
  meta: {
    menuTitle: 'Community',
  },
  // redirect: 'community-hub',
  children: [
    {
      path: '__', // external
      name: 'discord',
      redirect: '//discord.gg/maplestory', // external
      meta: {
        menuTitle: 'Discord',
      },
    },
    {
      path: '__', // external
      name: 'forums',
      redirect: '//forums.maplestory.nexon.net', // external
      meta: {
        menuTitle: 'Forums',
      },
    },
    {
      path: 'bean-brigade/:category?',
      name: 'bean-brigade',
      redirect: '/',
    },
    {
      path: 'media/:mediaType?',
      name: 'media',
      component: () => import('@/views/community/MediaView.vue'),
      meta: {
        menuTitle: 'Media',
        subTitle: 'MORE CONTENT TO ENJOY',
        banners: ['explore-maplestory', 'classes-and-jobs', 'news'],
        seo: {
          description: 'More Maple content to enjoy with screenshots, videos, and PC/Mobile wallpaper!',
          keywords:
            'maplestory screenshots, maplestory wallpaper, maplestory videos, maplestory desktop wallpaper, maplestory iphone wallpaper, maple world screenshots, maple world wallpaper',
        },
        gaPageType: 'media',
      },
    },
  ],
}

export default communityRoute
