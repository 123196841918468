import MainMenu from '@/views/MainMenu.vue'
import type { RouteRecordRaw } from 'vue-router'

const newsRoute: RouteRecordRaw = {
  path: '/news',
  name: 'news',
  component: MainMenu,
  redirect: { name: 'news-index' },
  meta: {
    menuTitle: 'News',
    subTitle: 'CHECK OUT THE LATEST NEWS',
    banners: ['player-ranking', 'maple-guides', 'server-status'],
    seo: {
      description: 'Check out the latest news and read about upcoming content, ongoing events, and more!',
    },
    gaPageType: 'news - all',
  },
  children: [
    {
      path: ':oldNewsId(\\d+)/:seo?',
      component: () => import('@/views/news/redirectionForOldID.vue'),
    },
    {
      path: ':newsType?',
      name: 'news-index',
      component: () => import('@/views/news/index.vue'),
      children: [
        {
          path: '',
          name: 'news-list',
          component: () => import('@/views/news/NewsList.vue'),
          meta: {
            seo: {
              keywords:
                'maplestory news, maplestory patch notes, maplestory events, gms patch, gms update, maplestory cash shop, maplestory update',
            },
          },
        },
        {
          path: ':newsId(\\d+)/:seo?',
          name: 'news-detail',
          component: () => import('@/views/news/NewsDetail.vue'),
          meta: {
            gaPageType: 'news - details',
          },
        },
      ],
    },
  ],
}

export default newsRoute
